import {
  Point
} from './point.js';

export class Wave {
  constructor(index, totalPoints, color, posY) {
    this.index = index;
    this.totalPoints = totalPoints;
    this.color = color;
    this.points = [];
    this.centerY = posY;
    this.pointMax = 0;
  }

  resize(stageWidth, stageHeight) {
    this.stageWidth = stageWidth;
    this.stageHeight = stageHeight;

    this.centerX = stageWidth / 2;
    //this.centerY = stageHeight / 2;

    this.pointGap = this.stageWidth / (this.totalPoints - 1);

    this.init();
  }

  init() {
    this.points = [];

    for (let i = 0; i < this.totalPoints; i++) {
      const point = new Point(
        this.index + i,
        this.pointGap * i,
        this.centerY
      );
      this.points[i] = point;
    }
  }

  draw(ctx, posY, bootstrap) {
    ctx.beginPath();
    ctx.fillStyle = this.color;

    // last point add to the right y position
    this.points[this.totalPoints-1].y = posY + 90;

    let prevX = this.points[0].x;
    let prevY = this.points[0].y + posY;

    ctx.moveTo(prevX, prevY);

    // bootstrap start and end
    let max = 32;
    if (bootstrap === 'start') {

      if (this.pointMax >= 32) {
        max = 32;
      } else {
        this.pointMax = this.pointMax + 0.5;
        max = this.pointMax;
      }
    }
    if (bootstrap === 'stop') {
      if (this.pointMax <= 0) {
        max = 0;
      } else {
        this.pointMax = this.pointMax - 0.5;
        max = this.pointMax;
      }
    }

    for (let i = 1; i < this.totalPoints; i++) {
      if (i < this.totalPoints - 1) {
        this.points[i].update(posY, max);
      }

      const cx = (prevX + this.points[i].x) / 2;
      const cy = (prevY + this.points[i].y) / 2;

      ctx.quadraticCurveTo(prevX, prevY, cx, cy);

      prevX = this.points[i].x;
      prevY = this.points[i].y;
    }

    ctx.lineTo(prevX, prevY);
    ctx.lineTo(this.stageWidth, this.stageHeight);
    ctx.lineTo(this.points[0].x, this.stageHeight);
    ctx.fill();
    ctx.closePath();
  }
}
