<template>
  <div
    :id="'block-' + id"
    :ref="'block-' + id"
    class="col-span-1 shadow-xl flex flex-col text-center bg-white rounded-xl shadow overflow-hidden relative border-4 h-auto"
    :class="['bg-' + color + '-' + bg + ' border-' + color + '-900', {'opacity-0': isNew}]"
  >

    <div class="absolute z-10 w-full h-full">
      <div class="rounded-md h-full opacity-20 border-3"
           style="border-radius: 8px;"
           :style="'box-shadow: inset 0 0 100px 0 #' + colorDeep + '; border-color: #' + colorLight">
      </div>
    </div>

    <div
      class="z-20 p-4 relative shadow-none flex items-center"
      :class="'bg-transparent border-' + color + '-300'"
    >
      <div class="flex-shrink-0">
        <span class="rounded-lg inline-flex p-2"
          :class="'bg-' + color + '-50 border-' + color + '-300 border-2'">
          <BlockIcon
            @click="blockEdit"
            v-bind:type="faceType"
            v-bind:color="color"
          ></BlockIcon>
        </span>
      </div>
      <div class="flex-1">
        <svg
          @click="blockEdit"
          class="h-1 cursor-pointer z-10 text-gray-900 text-lg font-bold inline-block"
          style="font-family: 'Roboto Mono', monospace;width: 100%; height: 28px;">
          <text :style="'stroke: #' + colorOutline + ';'" text-anchor="middle" x="50%" y="24" fill="white" stroke-width="4" letter-spacing="2" paint-order="stroke">{{formattedRemainingTime.value}}</text>
        </svg>

        <span @click="blockEdit" class="cursor-pointer font-bold" :class="'text-' + color + '-900'">{{title}}</span>
      </div>
      <div v-if="showControls" class="flex-shrink-1 items-center">
        <button
          @click="toggle"
          :class="'text-' + color + '-600 hover:text-' + color + '-900'">
          <svg v-if="!isActive" class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd"></path></svg>
          <svg v-else class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
        </button>
      </div>

      <button v-if="!embed" @click="blockEdit"
        class="z-20 top-1 left-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <svg class="w-4 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
      </button>

      <button v-if="!embed"
        class="cursor-move block-drag-handle z-20 top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="5" cy="9" r="1" /><circle cx="5" cy="15" r="1" /><circle cx="12" cy="9" r="1" /><circle cx="12" cy="15" r="1" /><circle cx="19" cy="9" r="1" /><circle cx="19" cy="15" r="1" />
        </svg>
      </button>

      <button v-if="!embed" @click="deleteBlock"
        class="z-20 top-1 right-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <span class="sr-only">Close</span>
        <!-- Heroicon name: x -->
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
  </div>

</template>
<script>
  import {colors} from '../colors/colors.js';
  import BlockIcon from './BlockIcon.vue';
  import {useToast} from "vue-toastification";
  import { useNativeNotifications } from "vue3-native-notification";
  import anime from "animejs";

  export default {
    name: 'BlockCounter',
    components: {
      BlockIcon
    },
    props: {
      id: String,
      title: String,
      color: String,
      size: Number,
      src: String,
      faceType: {
        default: 'default',
        type: String
      },
      elapsedTime: Number,
      preview: {
        default: false,
        type: Boolean
      },
      embed: {
        default: false,
        type: Boolean
      },
      embedPreview: {
        default: false,
        type: Boolean
      },
      isLoading: {
        default: false,
        type: Boolean
      },
      hasError: {
        default: false,
        type: Boolean
      },
      isNew: {
        default: false,
        type: Boolean
      },
      embedOptions: Object,
      totalTime: Number,
      activeSince: Number,
      activeCountdownSince: Number,
      elements: Array,
      allowTemplateAdd: Boolean,
      refID: String,
      notificationSent: {
        default: false,
        type: Boolean
      }
    },
    setup() {
      const toast = useToast();
      const nativeNotification = useNativeNotifications();
      return { toast, nativeNotification };
    },
    data() {
      return {
        bg: '100',
        sizeOptionsHours: [
          {text: '00', value: 0},
          {text: '01', value: 1},
          {text: '02', value: 2},
          {text: '03', value: 3},
          {text: '04', value: 4},
          {text: '05', value: 5},
          {text: '06', value: 6},
          {text: '07', value: 7},
          {text: '08', value: 8}
        ],
        sizeOptionsMinutes: [
          {text: '00', value: 0},
          {text: '15', value: 0.25},
          {text: '30', value: 0.5},
          {text: '45', value: 0.75}
        ],
        colors
      }
    },
    computed: {
      isActive() {
        return !!this.activeCountdownSince;
      },
      colorLight() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[100];
      },
      colorDark() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[500];
      },
      colorDeep() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorGrad() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorOutline() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[900];
      },
      formattedRemainingTime() {
        const date = new Date(null);
        let overtime = false;
        let fullTime = this.size;
        if (this.elapsedTime > 0) {
          fullTime = fullTime - (this.elapsedTime / 1000);
          // we are out of the time - overtime
          if (fullTime < 0) {
            this.changeBG();
            fullTime = Math.abs(fullTime);
            overtime = true;
            return {status: true, value: '00:00'};
          }
        }
        date.setSeconds(fullTime);
        const utc = date.toUTCString();
        return {status: overtime, value: utc.substr(utc.indexOf(":") + 1, 5)};
      },
      showControls() {
        if (this.embed === true && this.embedOptions.mode === 'view') {
          return false;
        } else {
          return true;
        }
      }
    },
    methods: {
      start() {
        this.nativeNotification.requestPermission();
        this.$emit('countdownStart', this.id);
      },
      stop() {
        this.bg = '100';
        this.$emit('countdownEnd', this.id);
        this.$emit('blockNotificationUpdate', this.id, false);
      },
      reset() {
        this.$emit('blockNotificationUpdate', this.id, false);
        this.$emit('blockReset', this.id);
      },
      toggle() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          if (this.embed === true && this.embedPreview === true) return;
          if (this.isActive) {
            this.stop();
          } else {
            this.start();
          }
        }
      },
      blockEdit() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockEditStart', this.id);
        }
      },
      deleteBlock() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockDelete', this.id, this.refID);
        }
      },
      changeBG() {
        if (this.bg === '100') {
          this.bg = '300';
        } else {
          this.bg = '100';
        }
      }
    },
    mounted() {
      if (this.isNew) {
        this.$emit('blockAdded', this.id);
        const block = document.querySelector("#block-" + this.id);
        //block.style.transform = "scale(0.5)";
        block.style.zIndex = 10000000;

        anime({
          targets: '#block-' + this.id,
          easing: 'linear',
          duration: 380,
          opacity: [1, 1],
          rotate: [3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          delay: 600,
          //scale: [{value: 0.5},{value: 1}],
          matrix3d: [
            '0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.011, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.131, 0, 0, 0, 0, 1.131, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.147, 0, 0, 0, 0, 1.147, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.056, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.129, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.097, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1'
          ],
          complete: () => {
            anime.remove('#block-' + this.id);
            block.style.zIndex = 'auto';
          }
        });
      }
    }
  }
</script>

<style>
</style>
