export class Particle {
  constructor(index, color) {
    this.index = index;
    this.color = color;
    this.wind = ((Math.random() * 1) + 1) - 1;
    this.speed = ((Math.random() * 5) + 1) + 1 - 1.5;
    this.size = Math.floor(Math.random() * (8 - 2) + 2);
    this.location = {
      x: (Math.random() * 260),
      y: 600
    };
    this.sizeAlt = 0;
  }

  resize (stageWidth, stageHeight) {
    this.stageWidth = stageWidth;
    this.stageHeight = stageHeight;
    this.init(stageWidth, stageHeight);
  }

  init() {
    this.wind = ((Math.random() * 1) + 1) - 1.5;
    this.speed = ((Math.random() * 5) + 1) + 1 - 1;
    this.size = Math.floor(Math.random() * (8 - 2) + 2);

    this.location = {
      x: (Math.random() * this.stageWidth),
      y: this.stageHeight
    };
  }

  draw(ctx, bootstrap) {

    if (bootstrap === 'start') {
      if (this.sizeAlt >= this.size) {
        this.sizeAlt = this.size;
      } else {
        this.sizeAlt = this.sizeAlt + 0.3;
      }
    }
    if (bootstrap === 'stop') {
      this.sizeAlt = this.sizeAlt - 0.3;
      if (this.sizeAlt <= 0) {
        this.sizeAlt = 0;
        this.location = {
          x: (Math.random() * this.stageWidth),
          y: this.stageHeight
        };
      }
    }

    if (this.location.y < -10 || this.location.x < -10 || this.location.x > this.stageWidth + 10) {
      this.init();
    }
    if (this.location.y > this.stageHeight - 100) {
      this.sizeAlt = (this.stageHeight - this.location.y) * 0.09;
      if (this.sizeAlt > this.size) {
        this.sizeAlt = this.size;
      }
    }

    this.location.x += this.wind;
    this.location.y -= this.speed;

    ctx.beginPath();
    ctx.fillStyle = this.color;
    //ctx.lineWidth = 2;
    //ctx.strokeStyle = this.color;
    ctx.arc(this.location.x, this.location.y, this.sizeAlt, 0, 2 * Math.PI);
    //ctx.stroke();
    ctx.fill();
  }
}
