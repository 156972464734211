export const particles = {
  fpsLimit: 60,
  particles: {
    color: {
      value: {}
    },
    move: {
      attract: {
        enable: true,
            distance: 200
      },
      direction: 'top',
          enable: true,
          outModes: {
      default: 'out'
      },
      random: false,
          speed: 2,
          size: true,
          straight: false
    },
    number: {
      density: {
        enable: true,
            area: 800
      },
      value: 200
    },
    opacity: {
      value: 0.5
    },
    shape: {
      type: ['circle']
    },
    size: {
      random: {
        enable: true,
            minimumValue: 2
      },
      animation: {
        enable: true,
            speed: 3,
            minimumValue: 3
      },
      value: 5
    }
  },
  detectRetina: true
};
