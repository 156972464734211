export class Point {
  constructor (index, x, y) {
    this.x = x;
    this.y = y;
    this.fixedY = y;
    this.speed = 0.06;
    this.cur = index;
    this.max = 32; //Math.random() * 100;
  }

  update(posY, max) {
    this.cur += this.speed;
    this.y = posY + this.fixedY + (Math.sin(this.cur) * max);
  }
}
