export const skins = [
  {
    text: 'Neutral',
    value: 'neutral',
    colors: {
      50: 'FACC15',
      900: 'D97706'
    }
  },
  {
    text: 'Light',
    value: 'light',
    colors: {
      50: 'FBE0C4',
      900: 'EBC2A0'
    }
  },
  {
    text: 'Medium Light',
    value: 'mediumlight',
    colors: {
      50: 'E4C39F',
      900: 'D5A27B'
    }
  },
  {
    text: 'Medium',
    value: 'medium',
    colors: {
      50: 'C69A73',
      900: 'B07A61'
    }
  },
  {
    text: 'Medium Dark',
    value: 'mediumdark',
    colors: {
      50: 'A56F46',
      900: '835031'
    }
  },
  {
    text: 'Dark',
    value: 'dark',
    colors: {
      50: '644E41',
      900: '44312A'
    }
  },
];
