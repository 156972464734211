import {WaveGroup} from './wavegroup.js';
import {Particles} from './particles.js';

export class Canvas {
  constructor(id, color) {
    this.color = color;
    this.id = id;
    this.active = false;
    this.canvasEl = document.getElementById("canvas-" + id);
    this.ctx = this.canvasEl.getContext('2d');
    this.elapsedTime = this.canvasEl.getAttribute('elapsed-time');
    this.size = this.canvasEl.getAttribute('size');
    this.bootstrap = null;
    this.inState = 0; // duration how long we are in this state

    this.stageWidth = this.canvasEl.clientWidth;
    this.stageHeight = this.canvasEl.clientHeight;

    this.canvasEl.width = this.stageWidth * 2;
    this.canvasEl.height = this.stageHeight * 2;
    this.ctx.scale(2, 2);

    this.waveGroup = new WaveGroup(this.color);
    this.particles = new Particles(this.color);

    this.resize(this.stageWidth, this.stageHeight);

    requestAnimationFrame(this.animate.bind(this));
  }

  update(color) {
    this.color = color;
    this.size = this.canvasEl.getAttribute('size');

    this.stageWidth = this.canvasEl.clientWidth;
    this.stageHeight = this.canvasEl.clientHeight;

    this.canvasEl.width = this.stageWidth;
    this.canvasEl.height = this.stageHeight;

    this.waveGroup = new WaveGroup(this.color);
    this.particles = new Particles(this.color);

    this.resize(this.stageWidth, this.stageHeight);
  }

  resize(stageWidth, stageHeight) {
    this.waveGroup.resize(stageWidth, stageHeight);
    this.particles.resize(stageWidth, stageHeight);
  }

  draw(ctx) {

    // calculate in state - transition to state
    if (this.bootstrap) {
      this.inState = this.inState + 1;
      if (this.inState >= 80) {
        this.bootstrap = null;
        this.inState = 0;
      }
    }

    if (this.active || this.bootstrap) {
      const posY = this.posY() - 90;
      this.waveGroup.draw(ctx, posY, this.active, this.bootstrap);
      this.particles.draw(ctx, this.bootstrap);
    } else {
      const posY = this.posY();
      this.waveGroup.draw(ctx, posY, this.active, this.bootstrap);
    }
  }

  animate() {
    this.ctx.clearRect(0, 0, this.stageWidth, this.stageHeight);
    this.draw(this.ctx);
    //console.log('hellp - ' + this.id);
    requestAnimationFrame(this.animate.bind(this));
  }

  posY() {
    this.elapsedTime = this.canvasEl.getAttribute('elapsed-time');
    let size = this.size / 1;
    let progressAdd = 0;

    if (this.size < 2) {
      let height = 2 - this.size;
      progressAdd = ((height * 60) * 60) * 1000;
      size = 2;
    }

    const percent = (((size * 60) * 60) * 1000) / 100;
    const progress = ((this.elapsedTime / 1) + progressAdd) / percent;

    const body = this.stageHeight - 90;
    let posY = (progress * (body / 100)) + 90;

    return posY;
  }

  start() {
    setTimeout(() => {
      this.bootstrap = 'start';
      this.inState = 0;
      this.active = true;
    }, 250);
  }

  stop() {
    this.bootstrap = 'stop';
    this.inState = 0;
    this.active = false;
  }
}
